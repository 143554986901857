<template>
  <div class="text-center">
    <v-dialog v-model="visible" width="800" @click:outside="$emit('close')">
      <v-card>
        <v-card-title>
          Alterar - Transportadora e Serviço de Entrega
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <div class="mt-4">
            <v-row>
              <v-col :cols="12">
                <v-text-field :value="dados.pedido_canal_venda" label="Número do Pedido" readonly
                  outlined></v-text-field>
              </v-col>
              <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field :value="dados.transportadora_nome" label="Transportadora" readonly
                  outlined></v-text-field>
              </v-col>
              <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field :value="dados.servico_entrega" label="Serviço Entrega" readonly outlined></v-text-field>
              </v-col>
              <v-col :cols="12">
                <v-select
                  :items="transportadoras"
                  label="Selecione a Transportadora - Serviço de Entrega"
                  v-model="new_transportadora"
                  item-text="descricao"
                  item-value="index"
                  :loading="loadingTransportadora"
                  outlined
              >
              <template #progress>

              </template>
            </v-select>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="$emit('close')">
            FECHAR
          </v-btn>
          <v-btn color="primary" text @click="atualizar" :disabled="new_transportadora == null">
            SALVAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { baseApiUrl, showError, showSuccess } from "@/global";
export default {
  data() {
    return {
      transportadoras: [],
      loadingTransportadora: false,
      new_transportadora: null
    }
  },

  methods: {
    carregarTransportadoras() {
      this.loadingTransportadora = true
      axios.get(`${baseApiUrl}/transportadora-de-para`)
        .then((res) => {
          this.transportadoras = [...res.data.transportadora]
          this.transportadoras.forEach((element, index) => {
            element.index = index
            element.descricao = `${element.nome} - ${element.servicoEntrega}` || ''
          })
          this.loadingTransportadora = false
        })
        .catch((err) => {
          console.log(err)
          showError("Erro ao carregar dados!")
          this.loadingTransportadora = false
        })
    },

    atualizar () {
      const aux = this.transportadoras.find(transportadora => transportadora.index === this.new_transportadora)
      const payload = {
        codigo_transportadora_sisfrete: aux.codigoSisfrete,
        transportadoraNome: aux.nome,
        servicoEntrega: aux.servicoEntrega,
        base: aux.base
      }
      axios.put(`${baseApiUrl}/new-order-transportadora/${this.dados.pedido_canal_venda}`, payload)
        .then(() => {
          showSuccess("Transportadora/Serviço atualizados com sucesso!")
          this.$emit('reload')
          this.$emit('close')
        })
        .catch((err) => {
          console.log(err)
          showError("Erro ao atualizar transportadora!")
        })
    }
  },

  props: {
    visibleDialog: Boolean,
    dados: [Array, Object]
  },

  computed: {
    visible() {
      return this.visibleDialog
    }
  },

  watch: {
    visibleDialog(newValue) {
      if (newValue) {
        this.carregarTransportadoras()
      } else {
        this.new_transportadora = null
      }
    }
  }
}
</script>

<style scoped></style>