<template>
  <v-row justify="center">
    <v-dialog v-model="dialogVisible" width="900" @click:outside="$emit('close', false)">
      <v-card class="mx-auto">
        <v-toolbar
          flat
          color="#018656"
          dark
        >
          <v-toolbar-title>{{ edit ? 'Editar' : 'Cadastrar' }} Chave Cotação</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('close', false)">
            <v-icon color="white" dense small>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mt-5">
          <v-row dense>
            <v-col cols="12">
              <v-text-field dense outlined color="#018454" v-model="form.nome" persistent-placeholder
              label="Nome"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field dense outlined color="#018454" v-model="form.valor" persistent-placeholder
              label="Token"></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-select dense persistent-placeholder label="Canal de Vendas" v-model="form.idcanal_vendas" clearable
              :items="canal_vendas" item-text="nome" item-value="idcanal_vendas" outlined color="#018454"></v-select>
            </v-col>

            <v-col cols="4">
              <p class="font-weight-bold">Tipo:</p>
              <v-radio-group v-model="form.tipo_token" mandatory row>
                <v-radio
                  color="#018454"
                  label="Cotação"
                  :value="3"
                ></v-radio>
                <v-radio
                  color="#018454"
                  label="Conferência"
                  :value="2"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="4">
              <p class="font-weight-bold">Stop embalamento:</p>
              <v-checkbox dense v-model="form.tipo" :disabled="form.tipo_token === 2" label="Embalamento Manual"
              color="#018454"></v-checkbox>
            </v-col>

            <v-col cols="4" class="d-flex flex-column">
              <v-switch v-model="form.troca_devolucao" color="#018454" label="Troca/Devolução" dense hide-details inset></v-switch>
              <v-switch v-model="form.ignorar_integracao" color="#018454" label="Ignorar Integração" dense inset></v-switch>
            </v-col>
            
            <v-col cols="12" style="width: 100%;" class="d-flex justify-end mt-2">
              <v-btn @click="save()" style="color: white;" color="#018454" dense :disabled="!form.nome && !form.nome">
                Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import { baseApiUrl, showSuccess, showError } from "@/global";
export default {
  data () {
    return {
      canal_vendas: [],
      form: {
        nome_original: '',
        nome: '',
        valor: '',
        tipo: false,
        tipo_token: 3,
        notificacao: 0,
        idcanal_vendas: 0,
        troca_devolucao: 0,
        ignorar_integracao: 0
      }
    }
  },

  props: {
    dialog: Boolean,
    edit: Boolean,
    log: Boolean,
    objeto: Object
  },

  methods: {
    async save() {
      this.form.tipo = this.form.tipo_token === 2 ? 2 : this.form.tipo ? 1 : 0;
      this.form.cod_empresa = localStorage.getItem("factory");
      this.form.nome_original = this.form.nome;
      if(!this.edit) {
        let url = `${baseApiUrl}/token`
        await axios.post(url, this.form)
          .then((res) => {
            showSuccess("Chave cadastrada com sucesso!")
            this.$emit('atualizar');
            this.$emit('close');
          }).catch((err) => {
            console.log(err);
            showError("Ocorreu um erro ao cadastrar a chave")
          }
        )
      } else {
        let url = `${baseApiUrl}/token/${this.objeto.codigo}`

        await axios.put(url, this.form)
          .then((res) => {
            showSuccess("Chave alterada com sucesso!")
            this.$emit('atualizar');
            this.$emit('close');
          }).catch((err) => {
            console.log(err)
            showError("Não foi possível alterar as informações")
          })
      }
    },

    async carregarCanais() {
      let url = `${baseApiUrl}/canal-venda`

      await axios.get(url)
        .then((res) => {
          this.canal_vendas = res.data.dados;
        }).catch((err) => {
          console.log(err)
        }
      )
    }
  },

  mounted() {
    this.carregarCanais();
  },

  watch: {
    dialog: function(newVal, oldVal) {
      if(newVal) {
        if(this.edit || this.log) {
          this.form.nome = this.objeto.nome;
          this.form.valor = this.objeto.valor;
          this.form.tipo = this.objeto.tipo;
          this.form.tipo_token = this.objeto.tipo_token;
          this.form.idcanal_vendas = this.objeto.idcanal_vendas;
          this.form.troca_devolucao = this.objeto.troca_devolucao;
          this.form.ignorar_integracao = this.objeto.ignorar_integracao;
        } else {
          this.form.nome = "";
          this.form.valor = "";
          this.form.tipo = false;
          this.form.tipo_token = 3;
          this.form.idcanal_vendas = 0;
          this.form.troca_devolucao = 0;
          this.form.ignorar_integracao = 0;
        }
      }
    }
  },

  computed: {
    dialogVisible: {
      get: function() {
        return this.dialog ? true : false
      },

      set: function() {}
    }
  }

}

</script>