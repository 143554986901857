<template>
  <div class="text-center">
    <v-dialog
      v-model="visible"
      @click:outside="$emit('close')"
      persistent
    >
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between mb-1" style="width: 100%;">
            <div style="display: block;">
              <span style="font-weight: 400 !important;">Pedido n° {{ dados.pedido_canal_venda }}</span>

              <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="dialogProdutos = true" small v-on="on" icon dense class="ml-4">
                      <v-icon size="18" color="#018454" dense>fas fa-shopping-cart</v-icon>
                    </v-btn>
                  </template>
                  <span>Produtos</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="dialogCotacao = true" small v-on="on" icon dense class="ml-2">
                      <v-icon size="18" color="#018454" dense>fas fa-truck</v-icon>
                    </v-btn>
                  </template>
                  <span>Cotação</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="dialogEmbalamento = true" small v-on="on" icon dense class="ml-2">
                      <v-icon size="18" color="#018454" dense>fas fa-box</v-icon>
                    </v-btn>
                  </template>
                  <span>Embalamento</span>
                </v-tooltip>
            </div>
            <v-btn size="small" color="success" @click="reprocessar">Reprocessar</v-btn>
          </div>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <div class="mt-4">
            <strong>Data Venda:</strong> {{ dados.data_venda }} <br>
            <strong>Status:</strong> {{ dados.status_pedido}} <br>
            <strong>Chave NFE:</strong> {{ dados.chave_nfe }} <br>
            <strong>Número Nota:</strong> {{ status[0].numero_nota }} <br>
            <strong>Data Emissão Nota:</strong> {{ status[0].data_emissao_nota }} <br>
            <strong>Série Nota:</strong> {{ status[0].serie_nota }} <br>
            <strong>CEP:</strong> {{ dados.destinatario_cep }} <br>
            <strong>Número Rastreio:</strong> {{ dados.numero_objeto }} <br>
            <strong>Número Pedido ERP:</strong> {{ status[0].codigo_pedido_abacos }} <br>
            <strong>Transportadora:</strong> {{ dados.transportadora_nome }} <br>
            <strong>Serviço Entrega:</strong> {{ dados.servico_entrega }} <br>
            <strong>Canal:</strong> {{ dados.canal_venda }} <br>
            <strong>Base:</strong> {{ dados.base }} <br>
            <strong>Código Serviço:</strong> {{ dados.codigo_servico }} <br>
            <strong>Valor Pedido:</strong> R$ {{ dados.valor_pedido.toLocaleString("pt-br", {minimumFractionDigits: 2,}) }} <br>
            <strong>Valor Frete:</strong> R$ {{ dados.valor_frete.toLocaleString("pt-br", {minimumFractionDigits: 2,}) }} <br>
            <strong>Mensagem:</strong> {{ dados.mensagem }} <br>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="$emit('close')">
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Produtos :visibleDialog="dialogProdutos" :dados="produtos" @close="dialogProdutos = false" />
    <Cotacao :visibleDialog="dialogCotacao" :cabecalho="dadosCabecalho" :dados="cotacao" :dadosRetorno="retornoCotacao" @close="dialogCotacao = false" />
    <Embalamento :visibleDialog="dialogEmbalamento" :cabecalho="dadosCabecalho" :dados="embalamento" :dadosRetorno="retornoEmbalamento" @close="dialogEmbalamento = false" />
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { baseApiUrl, showError } from "@/global";
import Produtos from './Produtos.vue'
import Cotacao from './Cotacao.vue'
import Embalamento from './Embalamento.vue'

  export default {
    data () {
      return {
        produtos: [],
        cotacao: [],
        retornoCotacao: [],
        embalamento: [],
        retornoEmbalamento: [],
        status: [{}],
        dadosCabecalho: {
          token: '',
          cepDestino: ''
        },
        dialogProdutos: false,
        dialogCotacao: false,
        dialogEmbalamento: false
      }
    },
    components: {
      Produtos,
      Cotacao,
      Embalamento
    },
    mounted() {
      this.dados.data_venda = moment(this.dados.data_venda).format("DD/MM/YYYY");
      this.carregarDetalhes(this.dados);
    },
    props: {
      visibleDialog: Boolean,
      dados: [Array, Object]
    },
    methods: {
      reprocessar () {
        this.$emit('reprocessar', this.dados.pedido_canal_venda)
        this.$emit('close')
      },

      async carregarDetalhes(item) {
        let url = `${baseApiUrl}/new-order-get/${item.numero_do_pedido}`

        await axios.get(url)
          .then((res) => {
            this.produtos = res.data[0].json_produtos ? JSON.parse(res.data[0].json_produtos) : ''
            if(res.data[0].json_sisfrete_requisicao) {
              this.cotacao = JSON.parse(res.data[0].json_sisfrete_requisicao).type.proced.search.items
              this.cotacao.forEach(element => {
                element.height = (element.height).toFixed(2);
                element.width = (element.width).toFixed(2);
                element.length = (element.length).toFixed(2);
              });
            }
            this.retornoCotacao = res.data[0].json_sisfrete ? JSON.parse(res.data[0].json_sisfrete).data[0] : []
            this.dadosCabecalho.token = res.data[0].json_sisfrete_requisicao ? 
            JSON.parse(res.data[0].json_sisfrete_requisicao).type.proced.code : '';
            this.dadosCabecalho.cepDestino = res.data[0].json_sisfrete_requisicao ? 
            JSON.parse(res.data[0].json_sisfrete_requisicao).type.proced.search.destination : ''
            if(res.data[0].json_sisfrete_embalamento_requisicao) {
              this.embalamento = JSON.parse(res.data[0].json_sisfrete_embalamento_requisicao).type.proced.search.items;
              this.embalamento.forEach(element => {
                element.height = (element.height).toFixed(2);
                element.width = (element.width).toFixed(2);
                element.length = (element.length).toFixed(2);
              });
            }
            this.retornoEmbalamento = res.data[0].json_sisfrete_embalamento ?
            JSON.parse(res.data[0].json_sisfrete_embalamento).data : []
            this.status[0] = res.data[0]
            this.status[0].numeroDoPedido = JSON.parse(res.data[0].json).availableOrderId.numeroDoPedido;
            if(this.status[0].data_emissao_nota != "" && this.status[0].data_emissao_nota != null) {
              this.status[0].data_emissao_nota = moment(this.status[0].data_emissao_nota).format("DD/MM/YYYY");
            }
          }).catch((err) => {
            console.log(err);
            showError("Erro ao carregar os detalhes")
          })
      }
    },

    computed: {
      visible () {
        return this.visibleDialog
      }
    }
  }
</script>
