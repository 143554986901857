<template>
  <div class="text-center">
    <v-dialog v-model="visible" @click:outside="$emit('close')" persistent>
      <v-card>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Produtos</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-data-table dense hide-default-footer :headers="grid.produtos.cabecalho" :items="dados"
          class="elevation-1">
        
            <template #item.valor="{ item }">
              {{ item.valor.toLocaleString("pt-br", {minimumFractionDigits: 2,}) }}
            </template>

          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="$emit('close')">
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        grid: {
          produtos: {
            cabecalho: [
              { text: 'Código', value: 'codigo', sortable: false },
              { text: 'Quantidade', value: 'quantidade', sortable: false },
              { text: 'Altura', value: 'altura', sortable: false },
              { text: 'Largura', value: 'largura', sortable: false },
              { text: 'Comprimento', value: 'comprimento', sortable: false },
              { text: 'Peso', value: 'peso', sortable: false },
              { text: 'Preço(R$)', value: 'valor', sortable: false },
            ]
          }
        }
      }
    },
    props: {
      visibleDialog: Boolean,
      dados: Array
    },
    computed: {
      visible () {
        return this.visibleDialog
      }
    }
  }
</script>