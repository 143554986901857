<template>
  <div>
    <!-- Início principal -->
    <v-card :loading="loading" style="height: 100%">
      <v-toolbar flat>
        <v-icon> fas fa-dolly-flatbed </v-icon>
        <v-toolbar-title class="pl-4">Novo Log integração</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text style="height: 100%">
        <v-row dense style="height: 100%">
          <v-col :cols="12">
            <v-row class="flex align-start" >
              <v-col xs="12" sm="12" md="6" lg="2" xl="2">
                <v-menu ref="menuInicial" v-model="menuInicial" :close-on-content-click="false"
                  :return-value.sync="filtros.dataInicial" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="dataInicial" label="Data Inicial" clearable prepend-icon="fas fa-calendar-alt"
                      outlined dense readonly v-bind="attrs" v-on="on"
                      @input="filtros.dataInicial = $event"></v-text-field>
                  </template>
                  <v-date-picker v-model="filtros.dataInicial" no-title locale="pt-br" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuInicial = false">
                      Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menuInicial.save(filtros.dataInicial)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col xs="12" sm="12" md="6" lg="2" xl="2">
                <v-menu ref="menuFinal" v-model="menuFinal" :close-on-content-click="false"
                  :return-value.sync="filtros.dataFinal" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="dataFinal" label="Data Final" clearable outlined dense
                      prepend-icon="fas fa-calendar-alt" readonly v-bind="attrs" v-on="on"
                      @input="filtros.dataInicial = $event"></v-text-field>
                  </template>
                  <v-date-picker v-model="filtros.dataFinal" no-title locale="pt-br" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuFinal = false">
                      Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menuFinal.save(filtros.dataFinal)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="4" xl="5">
                <div class="d-flex">
                  <v-btn class="mr-6" icon @click="carregarDados">
                    <v-icon>fas fa-search</v-icon>
                  </v-btn>
                  <v-text-field v-model="searchQuery" label="Buscar" dense outlined>
                  </v-text-field>
                  
                </div>
              </v-col>
              <v-col xs="12" sm="12" md="6" lg="4" xl="3">
                <v-btn color="primary" @click="dialogDatas = true">Filtrar
                </v-btn>
                <!-- <v-btn class="ml-2" color="success" @click="reprocessar">Reprocessar
                </v-btn> -->
                <v-btn v-if="filtrando" class="ml-2" color="warning" @click="limparFiltros">Limpar Filtros
                </v-btn>
                <v-btn v-if="selecteds.length && tab !== 2" class="ml-2" color="error" @click="action(true)">Excluir
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="12">
            <v-pagination v-model="page" color="success" :length="dados.last_page || 1"
              :total-visible="10"></v-pagination>
          </v-col>
          <v-col :cols="12">
            <v-tabs v-model="tab">
              <v-tab>Não integrado rastreio</v-tab>
              <v-tab>Não integrado auditoria</v-tab>
              <v-tab>Ignorado/Excluido Temporario</v-tab>
            </v-tabs>
            <v-tabs-items class="mt-8" v-model="tab">
              <span class="d-flex align-center justify-space-between">
                <v-chip style="margin-bottom: 20px;" color="primary" label outlined>
                  <strong>Todos os Pedidos: {{ dados.total }}</strong>
                </v-chip>
                <v-select class="mt-1" style="max-width: 200px; margin-left: 10px;" :items="items" v-model="per_page"
                  label="por página" dense outlined @change="perpagechange"></v-select>
              </span>
              <v-tab-item>
                <nao-integrado-rastreio v-if="!tab" :dados="filteredData" :tab="tab" :items="parseInt(per_page)"
                  @selected="selecteds = [...$event]" @reload="carregarDados"
                  @reprocessar="reprocessar($event)" @ativar="action(false, $event)"></nao-integrado-rastreio>
              </v-tab-item>
              <v-tab-item>
                <nao-integrado-rastreio v-if="tab === 1" :dados="filteredData" :tab="tab" :items="parseInt(per_page)"
                  @selected="selecteds = [...$event]" @reload="carregarDados"
                  @reprocessar="reprocessar($event)" @ativar="action(false, $event)"></nao-integrado-rastreio>
              </v-tab-item>
              <v-tab-item>
                <nao-integrado-rastreio v-if="tab === 2" :dados="filteredData" :tab="tab" :items="parseInt(per_page)"
                  @selected="selecteds = [...$event]" @reload="carregarDados"
                  @reprocessar="reprocessar($event)" @ativar="action(false, $event)"></nao-integrado-rastreio>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
        <v-dialog v-model="loading" persistent width="300">
          <v-card color="primary" dark>
            <v-card-text style="padding-top: 7px">
              Carregando...
              <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDatas" width="600">
          <v-card>
            <v-card-text style="padding: 20px;">
              <v-row>
                <v-col :cols="12">
                  <v-text-field v-model="filtros.codigo_pedido_erp" label="Número do Pedido" outlined></v-text-field>
                </v-col>
                <v-col :cols="12">
                  <v-text-field v-model="filtros.pedido_canal_venda" label="Pedido Canal de Vendas" outlined></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="handleClose">
                Cancelar
              </v-btn>
              <v-btn color="primary" text @click="filtrar">
                Filtrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { baseApiUrl, showError, showSuccess } from "@/global";
import naoIntegradoRastreio from '../components/integracao/logIntegracaoTable.vue';
export default {
  data() {
    return {
      tab: 0,
      menuInicial: null,
      page: 1,
      codigo_empresa: '',
      per_page: '10',
      items: ['10', '20', '30', '40', '50'],
      selecteds: [],
      searchQuery: '',
      repro: false,
      dados: [],
      filtrando: false,
      dialogDatas: false,
      loading: false,
      menuFinal: null,
      filtros: {
        dataInicial: '',
        dataFinal: '',
        codigo_pedido_erp: '',
        pedido_canal_venda: ''
      }
    }
  },

  components: {
    naoIntegradoRastreio
  },

  methods: {
    fechar() {
      this.$router.push("/")
    },

    perpagechange () {
      this.page = 1
      this.carregarDados()
    },

    action(value, unique) {
      this.$swal({
        title: 'Alerta',
        html: `Deseja mesmo ${value ? 'excluir' : 'ativar novamente'} ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true
          let aux = []
          if (!unique) {
            if (this.selecteds.length) {
              this.selecteds.forEach(item => {
                aux.push(item.numero_do_pedido)
              })
            } else {
              this.dados.data.forEach(item => {
                aux.push(item.numero_do_pedido)
              })
            }
          }
          else {
            aux.push(unique)
          }
          let payload = {
            marcar_ignorado: value,
            numero_do_pedido: aux
          }

          axios.put(`${baseApiUrl}/new-order-ignorar`, payload)
            .then(() => {
              this.loading = false
              this.carregarDados()
            })
            .catch(() => {
              this.loading = false
              showError('Erro ao realizar ação')
            })
        }
      })
    },

    handleClose() {
      this.dialogDatas = false
      if (!this.filtrando) {
        this.filtros.codigo_pedido_erp = ''
        this.filtros.pedido_canal_venda = ''
      }
    },

    reprocessar(unique) {
      this.loading = true
      let aux = []
      if (!unique) {
        if (this.selecteds.length) {
          this.selecteds.forEach(item => {
            aux.push(item.pedido_canal_venda)
          })
        } else {
          this.dados.data.forEach(item => {
            aux.push(item.pedido_canal_venda)
          })
        }
      }
      else {
        aux.push(unique)
      }

      axios.post(`${baseApiUrl}/new-order-reprocess/${this.codigo_empresa}/${unique}`)
        .then(() => {
          this.loading = false
          this.carregarDados()
          showSuccess('Pedido reprocessado com sucesso !!!')
        })
        .catch(() => {
          this.loading = false
          showError('Erro ao reprocessar')
        })
    },

    filtrar() {
      this.page = 1
      this.filtrando = true
      this.handleClose()
      this.carregarDados()
    },

    limparFiltros() {
      this.filtrando = false
      this.filtros.dataInicial = moment().subtract(30, 'days').format('YYYY-MM-DD'), // 'YYYY-MM-DD' é um formato padrão
      this.filtros.dataFinal = moment().format('YYYY-MM-DD'), // 'YYYY-MM-DD'
      this.filtros.pedido_canal_venda = ''
      this.filtros.codigo_pedido_erp = ''
      this.carregarDados()
    },

    carregarDados() {
      this.loading = true
      this.selecteds = []
      let query = !this.tab ? 'pedidos_nao_importados=1' : this.tab === 1 ? 'pedidos_nao_auditados=1' : 'ignorado_excluido=1'
      if (this.filtros.dataInicial && this.filtros.dataFinal && !this.filtrando) {
        query = `${query}&data_venda[de]=${moment(this.filtros.dataInicial).format('MM/DD/YYYY')}&data_venda[ate]=${moment(this.filtros.dataFinal).format('MM/DD/YYYY')}`
      }
      if (this.filtrando) {
        if (this.filtros.codigo_pedido_erp) {
          query = `${query}&codigo_pedido_erp=${this.filtros.codigo_pedido_erp}`
        }
        if (this.filtros.pedido_canal_venda) {
          query = `${query}&pedido_canal_venda=${this.filtros.pedido_canal_venda}`
        }
      }
      let url = `${baseApiUrl}/list-new-order?page=${this.page}&por_pagina=${parseInt(this.per_page)}&${query}`;
      axios.get(url)
        .then(res => {
          this.dados = res.data.dados
          this.loading = false
        })
        .catch((erro) => {
          showError(`${erro.response.data.erro}`)
          this.loading = false
        })
    }
  },
  computed: {
    dataInicial() {
      return this.filtros.dataInicial ? moment(this.filtros.dataInicial).format('DD/MM/YYYY') : ''
    },
    dataFinal() {
      return this.filtros.dataFinal ? moment(this.filtros.dataFinal).format('DD/MM/YYYY') : ''
    },

    filteredData() {
      if (this.dados.data) {
        if (!this.searchQuery) {
          return this.dados.data
        }

        const query = this.searchQuery.toLowerCase()
        return this.dados.data.filter(item => {
          return (
            (item.codigo_pedido_abacos?.toLowerCase().includes(query) ?? false) ||
            (item.pedido_canal_venda?.toLowerCase().includes(query) ?? false) ||
            (item.transportadora_nome?.toLowerCase().includes(query) ?? false) ||
            (item.servico_entrega?.toLowerCase().includes(query) ?? false) ||
            (item.canal_venda?.toLowerCase().includes(query) ?? false) ||
            (item.base?.toLowerCase().includes(query) ?? false) ||
            (item.sub_canal?.toLowerCase().includes(query) ?? false) ||
            (item.numero_nota?.toString().toLowerCase().includes(query) ?? false) ||
            (item.mensagem?.toLowerCase().includes(query) ?? false)
          )
        })
      } else {
        return []
      }
    }
  },

  watch: {
    page(newValue) {
      if (newValue) {
        this.carregarDados()
      }
    },
    tab() {
      this.page = 1
      this.carregarDados()
    }
  },

  mounted() {
    this.carregarDados()
    this.codigo_empresa = Number(this.$store.state.cod_empresa)
  },

}
</script>
