<template>
  <div class="text-center">
    <v-dialog v-model="visible" @click:outside="$emit('close')" width="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Json Requisição
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="$emit('close')">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-card-title>

        <pre id="jsonRequisicao" style="margin-top: 20px !important">
          {{ json.length > 0 ? json : "Não há dados disponíveis" }}
        </pre>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="copiarJson()"
          >
            Copiar
          </v-btn>

          <v-btn
            color="error"
            text
            @click="$emit('close')"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    visibleDialog: Boolean,
    json: Array
  },
  methods: {
    copiarJson() {
      var copytext = document.getElementById("jsonRequisicao")
      navigator.clipboard.writeText(copytext.innerHTML)
    }
  },
  computed: {
    visible () {
      return this.visibleDialog
    }
  }
}
</script>