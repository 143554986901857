<template>
  <div class="text-center">
    <v-dialog
      v-model="visible"
      @click:outside="$emit('close')"
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          {{ dados.identificador ? 'Editar' : 'Cadastrar' }} Transportadora
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-form ref="form" v-model="isValid">
            <v-row>
              <v-col xs="12" sm="12" md="12" lg="6" xl="6">
                <v-text-field
                  :loading="loading"
                  class="mt-6"
                  v-model="form.nome"
                  label="Nome Transportadora"
                  outlined
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="6" xl="6">
                <v-text-field
                  :loading="loading"
                  class="mt-6"
                  v-model="form.servicoEntrega"
                  label="Serviço de Entrega"
                  outlined
                  :rules="[rules.optional]"
                ></v-text-field>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="6" xl="6">
                <v-select
                  :loading="loading"
                  class="mt-6"
                  :items="transportadoras"
                  item-value="codigo"
                  :item-text="item => `${item.transportadora} - ${item.codigo}`"
                  clearable
                  :disabled="form.ignorarIntegracao ? true : false"
                  v-model="form.codigoSisfrete"
                  label="Código Transportadora"
                  outlined
                  :rules="[rules.optional]"
                ></v-select>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="6" xl="6">
                <v-text-field
                  :loading="loading"
                  class="mt-6"
                  v-model="form.codigoServico"
                  label="Código de Serviço"
                  outlined
                  :rules="[rules.optional]"
                ></v-text-field>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="6" xl="6">
                <v-text-field
                  :loading="loading"
                  class="mt-6"
                  v-model="form.base"
                  label="Base"
                  outlined
                  :rules="[rules.optional]"
                ></v-text-field>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="12" xl="12">
               <v-row>
                <v-col xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-checkbox
                    v-model="form.ignorarIntegracao"
                    :label="`Ignorar Integração`"
                  ></v-checkbox>
                </v-col>
                <v-col xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-checkbox
                    v-model="form.pausarEmbalamento"
                    :label="`Pausar Embalamento`"
                  ></v-checkbox>
                </v-col>
                <v-col xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-checkbox
                    v-model="form.naoEnviaCusto"
                    :label="`Não Envia Custo`"
                  ></v-checkbox>
                </v-col>
                <v-col xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-checkbox
                    v-model="form.expedicao"
                    :label="`Expedição`"
                  ></v-checkbox>
                </v-col>
               </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="$emit('close')">
            FECHAR
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="submitForm()">
            SALVAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { baseApiUrl, showError, showSuccess } from '@/global';
import axios from 'axios';

export default {
  data () {
    return {
      form: {
        nome: '',
        servicoEntrega: '',
        base: '',
        codigoSisfrete: '',
        codigoServico: '',
        ignorarIntegracao: false,
        pausarEmbalamento: false,
        expedicao: false,
        naoEnviaCusto: false,
      },
      transportadoras: [],
      loading: false,
      isValid: false, // A variável para verificar se o formulário é válido
      rules: {
        required: value => !!value || 'Campo obrigatório.',
        optional: value => true // Permite que o campo seja vazio
      }
    }
  },

  props: {
    visibleDialog: Boolean,
    log: Boolean,
    dados: [Array, Object]
  },

  computed: {
    visible () {
      return this.visibleDialog
    }
  },

  watch: {
    async visibleDialog (newValue) {
      this.loading = true;
      await this.carregarTransportadoras();
      if ((newValue && this.dados.identificador) || this.log) {
        // Filtra os campos de `this.dados` que estão presentes em `this.form`
        this.form = Object.keys(this.form).reduce((acc, key) => {
          if (this.dados[key] !== undefined) { // Verifica se a chave existe em `this.dados`
            acc[key] = this.dados[key]; // Se existir, atribui o valor correspondente
          }
          return acc;
        }, {});

        for(let i = 0; i < this.transportadoras.length; i++) {
          if(this.transportadoras[i].codigo == this.form.codigoSisfrete) {
            this.form.codigoSisfrete = this.transportadoras[i].codigo
          }
        }
      }
      if (!newValue) {
        this.resetForm();
      }
      this.loading = false;
    }
  },


  methods: {
    async carregarTransportadoras() {
      let url = `${baseApiUrl}/transportadoras`;

      await axios.get(url)
        .then((res) => {
          this.transportadoras = this.sortTransportadoras(res.data.transportadoras)
        })
        .catch((err) => {
          console.log(err);
        })
    },
    sortTransportadoras(obj) {
      return Object.values(obj).sort((a, b) => {
        return a.transportadora.localeCompare(b.transportadora);
      });
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.salvar();
      }
    },
    salvar () {
      if(this.form.codigoServico) {
        this.form.codigoServico = parseInt(this.form.codigoServico)
      }
      if (this.dados.identificador) {
        if(this.form.ignorarIntegracao || !this.form.codigoSisfrete) {
          this.form.codigoSisfrete = 0;
        }
        axios.put(`${baseApiUrl}/transportadora-de-para/${this.dados.identificador}`, this.form)
          .then(() => {
            showSuccess('Transportadora/Serviço editado com sucesso !!!');
            this.$emit('close');
            this.$emit('reload');
          })
          .catch(() => {
            showError('Erro ao realizar a edição');
          });
      } else {
        if(this.form.ignorarIntegracao || !this.form.codigoSisfrete) {
          this.form.codigoSisfrete = 0;
        }
        axios.post(`${baseApiUrl}/transportadora-de-para`, this.form)
          .then(() => {
            showSuccess('Transportadora/Serviço cadastrados com sucesso !!!');
            this.$emit('close');
            this.$emit('reload');
          })
          .catch(() => {
            showError('Erro ao realizar o cadastro');
          });
      }
    },
    resetForm() {
      this.form = {
        nome: '',
        servicoEntrega: '',
        base: '',
        codigoSisfrete: 0,
        codigoServico: '',
        ignorarIntegracao: false,
        pausarEmbalamento: false,
        expedicao: false,
        naoEnviaCusto: false
      };
      this.$refs.form.resetValidation();
    }
  }
}
</script>
