<template>
  <div class="text-center">
    <v-dialog v-model="visible" @click:outside="$emit('close')" persistent>
      <v-card>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Cotação | CEP Destino: {{ cabecalho.cepDestino }} | Token: {{ cabecalho.token }}</v-toolbar-title>
          
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="ml-4" icon @click="dialogRequisicao = true">
                <v-icon color="#018454">fas fa-clipboard</v-icon>
              </v-btn>
            </template>
            <span>Visualizar JSON</span>
          </v-tooltip>

          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="$emit('close', false)"
          >
            <v-icon color="white" dense small>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-data-table dense :headers="grid.cotacao.cabecalho" :items="dados" class="elevation-1"
          hide-default-footer>

            <template #item.valor="{ item }">
              {{ item.valor.toLocaleString("pt-br", {minimumFractionDigits: 2,}) }}
            </template>
        
          </v-data-table>
        </v-card-text>

        <v-toolbar
          flat
          class="mt-4"
        >
          <v-toolbar-title>Retorno Cotação</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-data-table dense hide-default-footer :items="dadosRetorno" :headers="grid.retorno.cabecalho"
          class="elevation-1"></v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="$emit('close')">
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <JsonRequisicao :visibleDialog="dialogRequisicao" :json="dados" @close="dialogRequisicao = false" />
  </div>
</template>

<script>
import JsonRequisicao from './jsonRequisicao.vue';
  export default {
    data() {
      return {
        grid: {
          cotacao: {
            cabecalho: [
              { text: 'Código', value: 'codigo', sortable: false },
              { text: 'Altura', value: 'altura', sortable: false },
              { text: 'Largura', value: 'largura', sortable: false },
              { text: 'Comprimento', value: 'comprimento', sortable: false },
              { text: 'Peso', value: 'peso', sortable: false },
              { text: 'Preço(R$)', value: 'valor', sortable: false },
              { text: 'Quantidade', value: 'quantidade', sortable: false },
            ]
          },
          retorno: {
            cabecalho: [
              { text: 'Base', value: 'Base', sortable: false },
              { text: 'Custo', value: 'custo', sortable: false },
              { text: 'Peso', value: 'peso', sortable: false },
              { text: 'Prazo Transp.', value: 'prazo_transporte', sortable: false },
              { text: 'Aliq. Acresc. Token', value: 'aliquota_acrescimo_token', sortable: false },
              { text: 'Base Acres. Token', value: 'base_acrescimo_token', sortable: false },
              { text: 'Valor Acresc. Token', value: 'valor_acrescimo_token', sortable: false },
              { text: 'Base Difal', value: 'base_difal', sortable: false },
              { text: 'Valor Difal', value: 'valor_difal', sortable: false },
              { text: 'Valor Produto Difal', value: 'valor_produto_difal', sortable: false },
              { text: 'Aliquota Difal', value: 'aliquota_difal', sortable: false },
              { text: 'Tipo Difal', value: 'tipo_difal', sortable: false },
              { text: 'Base Calc. Adicional', value: 'base_calc_adicional', sortable: false },
            ]
          }
        },
        dialogRequisicao: false
      }
    },
    components: {
      JsonRequisicao
    },
    props: {
      visibleDialog: Boolean,
      dados: Array,
      dadosRetorno: Array,
      cabecalho: Object
    },    
    computed: {
      visible () {
        return this.visibleDialog
      }
    }
  }
</script>