var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"pb-4",attrs:{"headers":_vm.headers,"items":_vm.dataTable,"hide-default-footer":"","show-select":"","items-per-page":_vm.items},on:{"input":_vm.select},scopedSlots:_vm._u([{key:"item.transportadora_nome",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openTransportadoras(item)}}},[_vm._v(" "+_vm._s(item.transportadora_nome)+" ")])]}},{key:"item.servico_entrega",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openTransportadoras(item)}}},[_vm._v(" "+_vm._s(item.servico_entrega)+" ")])]}},{key:"item.data_venda",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatData(item.data_venda))+" ")]}},{key:"item.mensagem",fn:function(ref){
var item = ref.item;
return [(item.mensagem == 'Canal de venda não encontrado.')?_c('v-chip',{attrs:{"link":"","label":""},on:{"click":function($event){return _vm.carregarFormCotacao(item)}}},[_vm._v(" "+_vm._s(item.mensagem)+" ")]):(item.mensagem == 'Transportadora não localizada.')?_c('v-chip',{attrs:{"link":"","label":""},on:{"click":function($event){return _vm.carregarFormTransportadora(item)}}},[_vm._v(" "+_vm._s(item.mensagem)+" ")]):_c('span',[_vm._v(_vm._s(item.mensagem))])]}},{key:"item.menu",fn:function(ref){
var item = ref.item;
return [(_vm.tab !== 2)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"width":"30px !important"},attrs:{"icon":"","dense":""},on:{"click":function($event){return _vm.$emit('reprocessar', item.pedido_canal_venda)}}},on),[_c('v-icon',{attrs:{"dense":"","small":"","color":"success"}},[_vm._v("fas fa-sync")])],1)]}}],null,true)},[_c('span',[_vm._v("Reprocessar")])]):_vm._e(),(_vm.tab !== 2)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"width":"30px !important"},attrs:{"icon":"","dense":""},on:{"click":function($event){return _vm.openDetalhes(item)}}},on),[_c('v-icon',{attrs:{"dense":"","small":"","color":"primary"}},[_vm._v("fas fa-info-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Detalhes")])]):_vm._e(),(_vm.tab !== 2)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"width":"30px !important"},attrs:{"icon":"","dense":""},on:{"click":function($event){return _vm.openTransportadoras(item)}}},on),[_c('v-icon',{attrs:{"dense":"","small":"","color":"warning"}},[_vm._v("fas fa-truck")])],1)]}}],null,true)},[_c('span',[_vm._v("Transportadoras/Serviço de Entrega")])]):_vm._e(),(_vm.tab === 2)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"width":"30px !important"},attrs:{"icon":"","dense":""},on:{"click":function($event){return _vm.$emit('ativar', item.pedido_canal_venda)}}},on),[_c('v-icon',{attrs:{"dense":"","small":"","color":"success"}},[_vm._v("fas fa-undo-alt")])],1)]}}],null,true)},[_c('span',[_vm._v("Ativar")])]):_vm._e()]}}])}),(_vm.dialogDetalhes)?_c('log-integracao-detalhes',{attrs:{"visibleDialog":_vm.dialogDetalhes,"dados":_vm.itemDetalhes},on:{"close":_vm.handleCloseDetalhes,"reprocessar":function($event){return _vm.$emit('reprocessar', $event)}}}):_vm._e(),_c('log-integracao-transportadora',{attrs:{"visibleDialog":_vm.dialogTransportadoras,"dados":_vm.itemTransportadoras},on:{"close":_vm.handleCloseTransportadoras,"reload":function($event){return _vm.$emit('reload')}}}),_c('chaves-cotacao-form',{attrs:{"dialog":_vm.dialogChavesForm,"objeto":_vm.formCotacao,"log":true},on:{"close":function($event){_vm.dialogChavesForm = false}}}),_c('transportadora-form',{attrs:{"visibleDialog":_vm.dialogTransportadoraForm,"dados":_vm.formTransportadora,"log":true},on:{"close":function($event){_vm.dialogTransportadoraForm = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }