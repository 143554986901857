<template>
  <div>
    <v-data-table
      class="pb-4"
      :headers="headers"
      :items="dataTable"
      hide-default-footer
      show-select
      :items-per-page="items"
      @input="select"
    >
    <template #item.transportadora_nome="{ item }">
      <span style="cursor: pointer;" @click="openTransportadoras(item)">
        {{ item.transportadora_nome }}
      </span>
    </template>
    <template #item.servico_entrega="{ item }">
      <span style="cursor: pointer;" @click="openTransportadoras(item)">
        {{ item.servico_entrega }}
      </span>
    </template>
    <template #item.data_venda="{ item }">
      {{ formatData(item.data_venda) }}
    </template>
    <template #item.mensagem="{ item }">
      <v-chip v-if="item.mensagem == 'Canal de venda não encontrado.'"
        link label @click="carregarFormCotacao(item)">
        {{ item.mensagem }}
      </v-chip>

      <v-chip v-else-if="item.mensagem == 'Transportadora não localizada.'"
      link label @click="carregarFormTransportadora(item)">
        {{ item.mensagem }}
      </v-chip>

      <span v-else>{{ item.mensagem }}</span>
    </template>
    <template #item.menu="{ item }">
      <v-tooltip top v-if="tab !== 2">
        <template v-slot:activator="{ on }">
          <v-btn style="width: 30px !important;" icon dense v-on="on" @click="$emit('reprocessar', item.pedido_canal_venda)">
            <v-icon dense small color="success">fas fa-sync</v-icon>
          </v-btn>
        </template>
        <span>Reprocessar</span>
      </v-tooltip>
      <v-tooltip top v-if="tab !== 2">
        <template v-slot:activator="{ on }">
          <v-btn style="width: 30px !important;" icon dense v-on="on" @click="openDetalhes(item)">
            <v-icon dense small color="primary">fas fa-info-circle</v-icon>
          </v-btn>
        </template>
        <span>Detalhes</span>
      </v-tooltip>
      <v-tooltip top v-if="tab !== 2">
        <template v-slot:activator="{ on }">
          <v-btn style="width: 30px !important;" icon dense v-on="on"  @click="openTransportadoras(item)">
            <v-icon dense small color="warning">fas fa-truck</v-icon>
          </v-btn>
        </template>
        <span>Transportadoras/Serviço de Entrega</span>
      </v-tooltip>
      <v-tooltip top v-if="tab === 2">
        <template v-slot:activator="{ on }">
          <v-btn style="width: 30px !important;" icon dense v-on="on" @click="$emit('ativar', item.pedido_canal_venda)">
            <v-icon dense small color="success">fas fa-undo-alt</v-icon>
          </v-btn>
        </template>
        <span>Ativar</span>
      </v-tooltip>
    </template>
  </v-data-table>
  <log-integracao-detalhes v-if="dialogDetalhes" :visibleDialog="dialogDetalhes" :dados="itemDetalhes" @close="handleCloseDetalhes" @reprocessar="$emit('reprocessar', $event)"></log-integracao-detalhes>
  <log-integracao-transportadora :visibleDialog="dialogTransportadoras" :dados="itemTransportadoras" @close="handleCloseTransportadoras" @reload="$emit('reload')"></log-integracao-transportadora>
  <chaves-cotacao-form :dialog="dialogChavesForm" :objeto="formCotacao" @close="dialogChavesForm = false" :log="true" />
  <transportadora-form :visibleDialog="dialogTransportadoraForm" :dados="formTransportadora" @close="dialogTransportadoraForm = false" :log="true" />
  </div>
</template>

<script>
import moment from 'moment';
import logIntegracaoDetalhes from './logIntegracaoDetalhes.vue';
import logIntegracaoTransportadora from './logIntegracaoTransportadora.vue';
import chavesCotacaoForm from '../chavesCotacao/form.vue';
import transportadoraForm from './formTransportadora.vue';
  export default {
    data() {
      return {
        formCotacao: {
          nome: '',
          valor: '',
          tipo: false,
          tipo_token: 3,
          notificacao: 0,
          idcanal_vendas: 0,
          troca_devolucao: 0,
          ignorar_integracao: 0
        },
        formTransportadora: {
          nome: '',
          servicoEntrega: '',
          base: '',
          codigoSisfrete: 0,
          codigoServico: '',
          ignorarIntegracao: false,
          pausarEmbalamento: false,
          expedicao: false,
          naoEnviaCusto: false
        },
        selected: [],
        itemDetalhes: {},
        itemTransportadoras: {},
        dialogDetalhes: false,
        dialogTransportadoras: false,
        dialogChavesForm: false,
        dialogTransportadoraForm: false,
        headers: 
          [
            {
                text: "Código Pedido",
                value: "codigo_pedido_abacos", // Atualizado
                sortable: false,
            },
            {
                text: "Número do Pedido",
                value: "pedido_canal_venda", // Atualizado
                sortable: false,
            },
            { text: "Transportadora", value: "transportadora_nome", sortable: false }, // Atualizado
            { text: "Serviço de Entrega", value: "servico_entrega", sortable: false }, // Atualizado
            { text: "Canal", value: "canal_venda", sortable: false }, // Atualizado
            { text: "Subcanal", value: "sub_canal", sortable: false },
            { text: "Base", value: "base", sortable: false }, // Mantido
            { text: "Número Nota", value: "numero_nota", sortable: false }, // Atualizado
            { text: "Data Venda", value: "data_venda", sortable: false }, // Mantido
            // { text: "Data Pedido", value: "data_pedido", sortable: false }, // Mantido como comentário
            { text: "Mensagem", value: "mensagem", sortable: false, width: '350px' }, // Mantido
            { sortable: false, value: "menu" },
        ]
      }
    },

    components: {
      logIntegracaoDetalhes,
      logIntegracaoTransportadora,
      chavesCotacaoForm,
      transportadoraForm
    },

    methods: {
      carregarFormCotacao(objeto) {
        this.formCotacao.nome = objeto.sub_canal;
        this.dialogChavesForm = true;
      },

      carregarFormTransportadora(objeto) {
        this.formTransportadora.nome = objeto.transportadora_nome;
        this.formTransportadora.servicoEntrega = objeto.servico_entrega;
        this.dialogTransportadoraForm = true;
      },

      formatData(val) {
        return val ? moment(val).format("DD/MM/YYYY") : ""
      },
      select (item) {
        this.$emit('selected', item)
      },

      handleCloseDetalhes() {
        this.dialogDetalhes = false
        this.itemDetalhes = {}
      },

      handleCloseTransportadoras() {
        this.dialogTransportadoras = false
        this.itemTransportadoras = {}
      },

      openDetalhes (item) {
        this.itemDetalhes = {...item}
        this.dialogDetalhes = true
      },

      openTransportadoras (item) {
        this.itemTransportadoras = {...item}
        this.dialogTransportadoras = true
      }
    },

    props: {
      dados: Array,
      tab: [String, Number],
      items: [Number,String]
    },

    computed: {
      dataTable () {
        return this.dados
      }
    }
  }
</script>
